/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu': {
    width: 14,
    height: 11,
    viewBox: '0 0 14 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.667 2.89a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.666zM5 1.111a.667.667 0 100 1.333h7.556a.667.667 0 100-1.333H5zm0 4a.667.667 0 100 1.333h7.556a.667.667 0 100-1.333H5zm-.667 4.666c0-.368.299-.666.667-.666h7.556a.667.667 0 110 1.333H5a.667.667 0 01-.667-.667zM3 5.556a1.333 1.333 0 11-2.666 0 1.333 1.333 0 012.666 0zm-1.333 5.333a1.333 1.333 0 100-2.666 1.333 1.333 0 000 2.666z" _fill="#fff"/>'
  }
})
